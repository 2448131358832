import { gql, useQuery } from '@apollo/client';
import { useRouteMatch } from 'react-router-dom';

const GET_CLAIM_NUMBER = gql`
  query GetClaimNumber($id: ID!) {
    claim(id: $id) {
      id
      confirmationNumber
    }
  }
`;

const GET_CASE_NUMBER = gql`
  query GetCaseNumber($id: ID!) {
    case(id: $id) {
      id
      externalId
    }
  }
`;

const useActiveDomainObject = () => {
  const caseRouteMatch = useRouteMatch<{ id: string }>('/cases/:id');

  const claimRouteMatch = useRouteMatch<{ id: string }>('/claims/:id');

  const caseId = caseRouteMatch?.params.id;
  const { data: caseData, loading: caseLoading } = useQuery(GET_CASE_NUMBER, {
    variables: {
      id: caseId,
    },
    skip: !caseId,
  });

  const claimId = claimRouteMatch?.params.id;
  const { data: claimData, loading: claimLoading } = useQuery(
    GET_CLAIM_NUMBER,
    {
      variables: {
        id: claimId,
      },
      skip: !claimId,
    },
  );

  if (!caseId && !claimId) return null;

  if (caseId && caseData) {
    return {
      id: caseData.case.id,
      number: caseData.case.externalId,
      type: 'Case' as const,
    };
  }

  if (claimId && claimData) {
    return {
      id: claimData.claim.id,
      number: claimData.claim.confirmationNumber,
      type: 'Case' as const,
    };
  }

  if (caseLoading || claimLoading) return null;

  return null;
};

export default useActiveDomainObject;
